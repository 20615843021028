export const WORDS = [
    "batty",
    "pride",
    "floss",
    "helix",
    "croak",
    "staff",
    "paper",
    "unfed",
    "whelp",
    "trawl",
    "outdo",
    "adobe",
    "crazy",
    "sower",
    "repay",
    "digit",
    "crate",
    "cluck",
    "spike",
    "mimic",
    "pound",
    "maxim",
    "linen",
    "unmet",
    "flesh",
    "booby",
    "forth",
    "first",
    "stand",
    "belly",
    "ivory",
    "seedy",
    "print",
    "yearn",
    "drain",
    "bribe",
    "stout",
    "panel",
    "crass",
    "flume",
    "offal",
    "agree",
    "error",
    "swirl",
    "argue",
    "bleed",
    "delta",
    "flick",
    "totem",
    "wooer",
    "front",
    "shrub",
    "parry",
    "biome",
    "lapel",
    "start",
    "greet",
  ];